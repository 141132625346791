<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Afficher</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="pageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>par Page</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-if="querySearch !== ''"
                v-model="filter"
                class="d-inline-block mr-1"
                placeholder="Recherche..."
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="dataOfTable"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :busy="isBusy"
        empty-text="No matching records found"
        :sort-desc.sync="sortDesc"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" />
            <strong>Chargement...</strong>
          </div>
        </template>
        <!-- Column: User -->
        <template #cell(user)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar"
                :text="avatarText(data.item.fullName)"
                :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
                :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              />
            </template>
            <b-link
              :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.fullName }}
            </b-link>
            <small class="text-muted">@{{ data.item.username }}</small>
          </b-media>
        </template>

        <!-- Column: Cover -->
        <template #cell(cover)="data">
          <b-img
            :src="`https://mfsgafrique.com/api-scientist/public${data.item.cover.contentUrl}`"
            width="64"
            alt="placeholder"
          />
        </template>

        <!-- Column: Role -->
        <template #cell(roles)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveUserRoleIcon(data.item.user.roles[0])"
              size="18"
              class="mr-50"
              :class="`text-${resolveUserRoleVariant(data.item.user.roles[0])}`"
            />
            <span class="align-text-top text-capitalize">{{ data.item.user.roles[0] }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(etat)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.user.isActive)}`"
            class="text-capitalize"
          >
            {{ data.item.user.isActive === true ? "active" : "Inactive" }}
          </b-badge>
        </template>

        <template #cell(statut)="data">
          <b-badge
            pill
            :variant="`light-${resolveIsAcceptStatusVariant(data.item)}`"
            class="text-capitalize"
          >
            {{ !data.item.hasOwnProperty('isAccept') ? 'En cours de traitement' : data.item.isAccept == 0 ? 'Demande rejeter' : 'Demande activer' }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-for="(item,index) in buttons"
              :key="index"
              @click="actionButton(item.name, data.item)"
            >
              <feather-icon :icon="item.icon" />
              <span class="align-middle ml-50">{{ item.name }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <!-- <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col> -->
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="total"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BImg, BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'Datatable',
  components: {
    BCard,
    BRow,
    BCol,
    BImg,
    BSpinner,
    BFormInput,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  props: {
    dataOfTable: {
      type: Array,
      required: true,
    },
    tableColumns: {
      type: Array,
      required: true,
    },
    buttons: {
      type: Array,
      required: true,
    },
    total: {
      type: Number,
      default: 0,
      required: true,
    },
    querySearch: {
      type: String,
      default: '',
    },
    resource: {
      type: String,
      default: '',
      required: true,
    },
    isBusy: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      perPage: 10,
      pageOptions: [10, 50, 150, 200],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      mainProps: {
        blank: true,
        width: 75,
        height: 75,
        class: 'm1',
      },
      filter: null,
      filterOn: [],
      items: [],
      show: false,
    }
  },
  watch: {
    dataOfTable(newValue) {
      console.log(newValue)
      this.filteredKB = newValue
    },
    perPage(newValue) {
      this.$store.dispatch(`${this.resource}/list`, { params: { page: this.currentPage, itemsPerPage: newValue } })
    },
    currentPage(newValue) {
      this.$store.dispatch(`${this.resource}/list`, { params: { page: newValue, itemsPerPage: this.perPage } })
    },
    filter(newValue) {
      const obj = { page: this.currentPage, itemsPerPage: this.perPage }
      obj[this.querySearch] = newValue
      this.$store.dispatch(`${this.resource}/list`, { params: obj })
    },
  },
  methods: {
    changeSort() {
      // this.$emit('filter', { domaine: this.domain, type: this.sortBy.toLowerCase(), order: this.sortDesc === true ? 'DESC' : 'ASC' })
    },
    actionButton(type, items) {
      // eslint-disable-next-line no-restricted-syntax
      for (const iterator of this.buttons) {
        if (iterator.name === type) {
          this.$emit(type, items)
          break
        }
      }
    },
    resolveUserRoleVariant(role) {
      if (role === 'ROLE_USER') return 'success'
      if (role === 'ROLE_MANAGER') return 'info'
      if (role === 'ROLE_ADMIN') return 'danger'
      return 'primary'
    },
    resolveUserRoleIcon(role) {
      if (role === 'ROLE_USER') return 'UserIcon'
      if (role === 'ROLE_MANAGER') return 'Edit2Icon'
      if (role === 'ROLE_ADMIN') return 'ServerIcon'
      return 'UserIcon'
    },
    resolveUserStatusVariant(status) {
      if (status === true) return 'success'
      if (status === false) return 'secondary'
      return 'primary'
    },
    resolveIsAcceptStatusVariant(item) {
      // eslint-disable-next-line no-prototype-builtins
      if (!item.hasOwnProperty('isAccept')) return 'secondary'
      if (item.isAccept === true) return 'success'
      if (item.isAccept === false) return 'danger'
      return 'primary'
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
